
import { dateFrancaise, variables } from "@/plugins/variables"
import axios from "axios"
import Vue from "vue"
import NonAcces from "../components/NonAcces.vue"

export default Vue.extend({
  name: "Horametre",
  components: { NonAcces },
  data() {
    return {
      unsubscribe: null as any,
      lesMachines: [] as any[],
      uneMachine: { sh_id: "" } as any,
      dictionnaire: [] as string[],
      snakbar_text: "",
      snak_visible: false
    }
  },
  methods: {
    initialiser() {
      axios.post(this.$store.state.ajaxurl + "Horametre.php", { action: "INITIALISATION_HORAMETRE" }).then(response => {
        this.lesMachines = response.data.lesMachines
      })
    },
    enregistrerHorametre() {
      axios.post(this.$store.state.ajaxurl + "Horametre.php", { action: "ENREGISTRER_HORAMETRE", lesMachines: this.lesMachines }).then(responce => {
        if (responce) {
          this.initialiser()
          this.uneMachine = { sh_id: "" }
        }
      })
    },
    voirHistorique(uneMachine: any) {
      this.uneMachine = uneMachine
    },
    formatageDate(date: string) {
      return dateFrancaise(date)
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = ["Enregistrer", "Machine", "Horamètre actuel", "Nouvelle valeur", "Historique horamètre", "Date", "Horamètre"]
          break
        case "AL":
          this.dictionnaire = ["Registrieren", "Maschine", "Betriebsstunden aktuell", "Neuer Wert", "Historie betriebsstunden", "Datum", "Betriebsstunden"]
          break
      }
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    autHorametre() {
      return variables.autorisation.includes("Horametre")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
