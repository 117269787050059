
import Vue from "vue"

export default Vue.extend({
  name: "NonAcces",
  data: () => ({
    dictionnaire: [] as string[],
    unsubscribe: null as any
  }),
  methods: {
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Vous n'avez pas les autorisations pour accéder a cette page.", //0
            "Si vous avez besoin d'accéder a cette page contactez", //1
            "l'administrateur", //2
            "ou une personne disposant des droits neccessaire pour vous donnez les autorisations neccessaire" //3
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Sie sind nicht berechtigt, auf diese Seite zuzugreifen.", //0
            "Wenn Sie auf diese Seite zugreifen müssen, kontaktieren Sie uns", //1
            "der Administrator", //2
            "oder eine Person mit den erforderlichen Rechten, um Ihnen die erforderlichen Berechtigungen zu erteilen" //3
          ]
          break
      }
    },
    about() {
      this.$router.push("/about")
    }
  },
  created() {
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
